.home_news_tag_page .tag {
  margin-left: 0.5em;
  margin-right: 0.5em;
  border-radius: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 0.05em solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  flex: 1;
}

.nav li a {
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 1.25em;
}
