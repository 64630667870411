.post_list {
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-bottom:30vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 65px);
}

.post_list .empty_list_placeholder {
  display: flex;
  justify-content: center;
  color: #999999;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 2em;
}
