.tag_screen_content {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F0F2F5;
}

.tag_screen_content .follow_or_unfollow {
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  align-items: center;
}

.tag_screen_content .follow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag_screen_content .unfollow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag_screen_content h3 {    
  font-size: 14px;
  font-weight: normal;
}

.tag_screen_content h1 {    
  font-size: 16px;
  line-height: 18px;  
  font-weight: normal;
}

.tag_screen_content section {
  margin-top: 0.1em;
  margin-bottom: 0.005em;
  background-color: #fff;
}


